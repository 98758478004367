//Todo: rework sass and less work.
@import "_variables";

.birthday-hero {

  margin-left: 30%;

  @media all and (max-width: 1200px) {

    margin-left: 17.5%;

  }

  @media all and (max-width: @bp-medium) {

    margin-left: 10%;

  }

  .hero-container {
    max-width: 10em;
    display: block;
    text-align: center;

    @media all and (max-width: @bp-medium) {
      max-width: 8em;
    }

  }

  .hero__text-large {
    margin-top: 1em;
    font-size: 4em;

    @media all and (max-width: @bp-medium) {
      font-size: 2.5em;
    }

    @media all and (max-width: @bp-xs) {
      font-size: 1.5em;
    }
  }

  .hero__text-medium {
    font-size: 2.75em;


    @media all and (max-width: @bp-xs) {
      font-size: 1em;
    }
  }

  .hero__text-small {
    font-size: 1.25em;
    padding-top: 0.5em;

    @media all and (max-width: @bp-small) {
      font-size: 0.75em;
    }

  }

  hr {
    margin-top: 2em;
    margin-bottom: 1.5em;
    margin-left: 0;
    width: 76px;
    height: 2px;
    text-align:left;
    border: #FFFFFF;
    background-color: #FFFFFF;
  }

  .btn {
    @media all and (max-width: @bp-xs) {
      margin-left: 0 !important;
    }
  }

}

.hero-promo-small {
  padding-bottom: 8em !important;
}

.hero-promo-background {

  background-image: url('../../../assets/images/heros/mid-year-campaign-desktop.jpg');

  @media all and (max-width: @bp-small) {
    background-image: url('../../../assets/images/heros/boost-mobile-2.jpg');
  }
}

.hero-birthday-promo-mobile {
  display: none;

  @media all and (max-width: @bp-small) {
    display: block;
  }
}

.hero-birthday-promo {
  display: block;

  @media all and (max-width: @bp-small) {
    display: none;
  }
}

.birthday-hero--body {
  padding-top: 4em !important;
  padding-bottom: 8em !important;

  @media all and (max-width: @bp-medium) {
    padding-bottom: 6em !important;
  }

  @media all and (max-width: @bp-small) {
    padding-top: 1em !important;
    padding-bottom: 20em !important;
  }

  @media all and (min-width: @bp-xxl) {

    padding-top: 8em !important;
    padding-bottom: 12em !important;

  }

}

.homepage-birthday-hero--body {
  padding-top: 8em !important;
  padding-bottom: 12em !important;


  @media all and (max-width: @bp-small) {
    padding-top: 2em !important;
    padding-bottom: 10em !important;
  }

}


.hero {
  .fa-chevron-right{
    padding-left: 0.5em !important;
  }

  .hero__left-image {
    background-position: bottom 0 left 0 !important;
    background-size: auto !important;
  }

  hr.hr--short {
    max-width: 2em;
    border-top: .375em solid #ffffff !important;
  }

  hr.hr--invisible{
    max-width: 2em;
    border-top: .375em solid transparent !important;
    margin-bottom: .1rem;
  }

  .hero__synopsis.hero__synopsis--small-print{
    font-size: 1em;
  }

  .col {
    .col__relative-child {
      position: relative;
      width: 100%;
      margin: 0;

      .col__relative-child--top-right-absolute {
        top: 0;
        right: 0;
        position: absolute;
      }
    }
  }

  .image-left-container--width {
    width: 30%;
  }

  .content-right-container--width {
    width: 70%;
    margin-left: 0;
  }

  .bordered {
    border: 1px solid black;
  }

  .no-margin-left {
    margin-left: 0;
  }

  .padding-top-medium {
    padding-top: 2em;
  }

  .no-margin-sides {
    margin-left: 0;
    margin-right: 0;
  }

  .hero__candle {
    padding-top: 3em;
    padding-bottom: 2em;
  }

  .hero__body--transparent {
    background-color: transparent;
  }

  .hero__btn {
    background-color: #334152;
  }

  @media all and (min-width: @bp-large) {

  }

  @media all and (max-width: @bp-xs) {
    .col {
      float: left;
    }

    .hr.hr--short {
      margin: 2em 0 3em 0;

    }

    .image-left-container--width {
      width: 40%;
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
    }

    .content-right-container--width {
      width: 58%;
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
    }

    .hero__left-image {
      background-position: bottom 0% left 0 !important;
      background-size: 50% 100% !important;
      background: none !important;
    }

    .padding-top-medium {
      padding-top: 1em;
    }

    .hero__body{
      p{
        margin-left: -70%;
        margin-right: -1em;

      }

      a.btn{
        margin-left: -70%;
      }
    }
  }

  @media all and (min-width: @bp-medium) and (max-width: @bp-large) {
    .image-left-container--width {
      width: 40%;
    }

    .content-right-container--width {
      width: 60%;
      margin-left: 0;
    }

    .hero__left-image {
      background-position: bottom 0 left 0 !important;
      background-size: auto !important;
    }

    .padding-top-medium {
      padding-top: 6em;
    }
  }

  @media all and (min-width: @bp-small) and (max-width: @bp-medium) {
    .col {
      float: left;
    }

    .image-left-container--width {
      width: 40%;
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
    }

    .content-right-container--width {
      width: 60%;
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
    }

    .hero__left-image {
      //background-position: bottom 0 left 0 !important;
      //background-size: auto !important;
      background: none !important;
    }

    .padding-top-medium {
      padding-top: 5em;
    }

  }

  @media all and (min-width: 480px) and (max-width: 739px) {
    .col {
      float: left;
    }

    .image-left-container--width {
      width: 40%;
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
    }

    .content-right-container--width {
      width: 60%;
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
    }

    .hero__left-image {
      //background-position: bottom 0 left 0 !important;
      //background-size: auto !important;
      background: none !important;
    }

    .padding-top-medium {
      padding-top: 2em;
    }

  }

  @media all and (min-width: @bp-xxl) {
    .hero__left-image {
      background-position: bottom 0 left 0 !important;
      background-size: auto !important;
    }

    .padding-top-medium{
      padding-top: 5em;
    }
  }

}