/*  480 / 16 */
/*  620 / 16 */
/*  740 / 16 */
/*  980 / 16 */
/* 1200 / 16 */
/* 1400 / 16 */
/* 1600 / 16 */
/* 2000 / 16 */
.birthday-hero {
  margin-left: 30%;
}
@media all and (max-width: 1200px) {
  .birthday-hero {
    margin-left: 17.5%;
  }
}
@media all and (max-width: 61.25em) {
  .birthday-hero {
    margin-left: 10%;
  }
}
.birthday-hero .hero-container {
  max-width: 10em;
  display: block;
  text-align: center;
}
@media all and (max-width: 61.25em) {
  .birthday-hero .hero-container {
    max-width: 8em;
  }
}
.birthday-hero .hero__text-large {
  margin-top: 1em;
  font-size: 4em;
}
@media all and (max-width: 61.25em) {
  .birthday-hero .hero__text-large {
    font-size: 2.5em;
  }
}
@media all and (max-width: 30em) {
  .birthday-hero .hero__text-large {
    font-size: 1.5em;
  }
}
.birthday-hero .hero__text-medium {
  font-size: 2.75em;
}
@media all and (max-width: 30em) {
  .birthday-hero .hero__text-medium {
    font-size: 1em;
  }
}
.birthday-hero .hero__text-small {
  font-size: 1.25em;
  padding-top: 0.5em;
}
@media all and (max-width: 46.25em) {
  .birthday-hero .hero__text-small {
    font-size: 0.75em;
  }
}
.birthday-hero hr {
  margin-top: 2em;
  margin-bottom: 1.5em;
  margin-left: 0;
  width: 76px;
  height: 2px;
  text-align: left;
  border: #FFFFFF;
  background-color: #FFFFFF;
}
@media all and (max-width: 30em) {
  .birthday-hero .btn {
    margin-left: 0 !important;
  }
}
.hero-promo-small {
  padding-bottom: 8em !important;
}
.hero-promo-background {
  background-image: url('../../../assets/images/heros/mid-year-campaign-desktop.jpg');
}
@media all and (max-width: 46.25em) {
  .hero-promo-background {
    background-image: url('../../../assets/images/heros/boost-mobile-2.jpg');
  }
}
.hero-birthday-promo-mobile {
  display: none;
}
@media all and (max-width: 46.25em) {
  .hero-birthday-promo-mobile {
    display: block;
  }
}
.hero-birthday-promo {
  display: block;
}
@media all and (max-width: 46.25em) {
  .hero-birthday-promo {
    display: none;
  }
}
.birthday-hero--body {
  padding-top: 4em !important;
  padding-bottom: 8em !important;
}
@media all and (max-width: 61.25em) {
  .birthday-hero--body {
    padding-bottom: 6em !important;
  }
}
@media all and (max-width: 46.25em) {
  .birthday-hero--body {
    padding-top: 1em !important;
    padding-bottom: 20em !important;
  }
}
@media all and (min-width: 100em) {
  .birthday-hero--body {
    padding-top: 8em !important;
    padding-bottom: 12em !important;
  }
}
.homepage-birthday-hero--body {
  padding-top: 8em !important;
  padding-bottom: 12em !important;
}
@media all and (max-width: 46.25em) {
  .homepage-birthday-hero--body {
    padding-top: 2em !important;
    padding-bottom: 10em !important;
  }
}
.hero .fa-chevron-right {
  padding-left: 0.5em !important;
}
.hero .hero__left-image {
  background-position: bottom 0 left 0 !important;
  background-size: auto !important;
}
.hero hr.hr--short {
  max-width: 2em;
  border-top: .375em solid #ffffff !important;
}
.hero hr.hr--invisible {
  max-width: 2em;
  border-top: .375em solid transparent !important;
  margin-bottom: .1rem;
}
.hero .hero__synopsis.hero__synopsis--small-print {
  font-size: 1em;
}
.hero .col .col__relative-child {
  position: relative;
  width: 100%;
  margin: 0;
}
.hero .col .col__relative-child .col__relative-child--top-right-absolute {
  top: 0;
  right: 0;
  position: absolute;
}
.hero .image-left-container--width {
  width: 30%;
}
.hero .content-right-container--width {
  width: 70%;
  margin-left: 0;
}
.hero .bordered {
  border: 1px solid black;
}
.hero .no-margin-left {
  margin-left: 0;
}
.hero .padding-top-medium {
  padding-top: 2em;
}
.hero .no-margin-sides {
  margin-left: 0;
  margin-right: 0;
}
.hero .hero__candle {
  padding-top: 3em;
  padding-bottom: 2em;
}
.hero .hero__body--transparent {
  background-color: transparent;
}
.hero .hero__btn {
  background-color: #334152;
}
@media all and (max-width: 30em) {
  .hero .col {
    float: left;
  }
  .hero .hr.hr--short {
    margin: 2em 0 3em 0;
  }
  .hero .image-left-container--width {
    width: 40%;
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }
  .hero .content-right-container--width {
    width: 58%;
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }
  .hero .hero__left-image {
    background-position: bottom 0% left 0 !important;
    background-size: 50% 100% !important;
    background: none !important;
  }
  .hero .padding-top-medium {
    padding-top: 1em;
  }
  .hero .hero__body p {
    margin-left: -70%;
    margin-right: -1em;
  }
  .hero .hero__body a.btn {
    margin-left: -70%;
  }
}
@media all and (min-width: 61.25em) and (max-width: 75em) {
  .hero .image-left-container--width {
    width: 40%;
  }
  .hero .content-right-container--width {
    width: 60%;
    margin-left: 0;
  }
  .hero .hero__left-image {
    background-position: bottom 0 left 0 !important;
    background-size: auto !important;
  }
  .hero .padding-top-medium {
    padding-top: 6em;
  }
}
@media all and (min-width: 46.25em) and (max-width: 61.25em) {
  .hero .col {
    float: left;
  }
  .hero .image-left-container--width {
    width: 40%;
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }
  .hero .content-right-container--width {
    width: 60%;
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }
  .hero .hero__left-image {
    background: none !important;
  }
  .hero .padding-top-medium {
    padding-top: 5em;
  }
}
@media all and (min-width: 480px) and (max-width: 739px) {
  .hero .col {
    float: left;
  }
  .hero .image-left-container--width {
    width: 40%;
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }
  .hero .content-right-container--width {
    width: 60%;
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }
  .hero .hero__left-image {
    background: none !important;
  }
  .hero .padding-top-medium {
    padding-top: 2em;
  }
}
@media all and (min-width: 100em) {
  .hero .hero__left-image {
    background-position: bottom 0 left 0 !important;
    background-size: auto !important;
  }
  .hero .padding-top-medium {
    padding-top: 5em;
  }
}
